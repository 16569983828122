import React from 'react'
import './global_stylesheets/Footer.css'

export default function Footer() {
    return (
        <div className='footer'>
            
        </div>
    )
}
